
<template>
  <Breadcrumbs main="Event" title="" />
  <div class="container-fluid">
  
  <div v-if="this.is_event_permission === 'Y'">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h1>อัปโหลดไฮไลท์</h1>
          <div class="col-xl-12 col-sm-12" style="text-align: center">
              <div class="d-flex justify-content-center">
            <h3>{{ this.eventDetails.title }}</h3>
          </div>
          </div>
          
          <div class="col-xl-12 col-sm-12" style="text-align: center">
            <img width="700"
              style="border-radius: 0.5rem;"
              :src="this.eventDetails.coverurl"
            />
          </div>
          
          <hr>
          <div class="justify-content-center">
            <div class="row">
              
              <div class="col-xl-12 col-sm-12" style="text-align: left;background-color: lightgray;">
                <b>&nbsp;</b><br>
                <b>คำแนะนำในการอัปโหลดไฮไลท์ เพื่อแสดงหน้าเว็บไซต์</b>
                <p>1.เป็นรูปที่มีขนาดไฟล์ไม่เกิน <font  style="color: red;"><u>4.5MB</u></font> <br>
                   2.คัดเลือกภาพที่สวยที่สุดเพื่อโชวนักวิ่งไม่เกิน 5 ภาพ ต่อ 1 กิจกรรม<br>
                   3.อัปโหลดไฟล์ JPEG (.jpg/.jpeg) เท่านั้น<br>
                   4.หาก upload แล้วพบข้อผิดพลาด หรือต้องการลบไฟล์นั้น กรุณาติดต่อแอดมินที่เพจ หรือ LINE@<br>
                </p>
                </div>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="col-sm-12">
    <div class="card card-btn-upload">
      &nbsp;
      <div class="row">
        <div class="col-xl-12 col-sm-6">
          <h5>รูปที่เลือกอัปโหลด: {{ upload_store_hl.selectedFiles.length }}</h5>
        </div>
        <!-- <div class="col-xl-3 col-sm-6">
          <h5>กำลังอัปโหลด: {{ upload_store_hl.uploading_count }}</h5>
        </div>
        <div class="col-xl-3 col-sm-6">
          <h5 style="color: green">
            อัปโหลดสำเร็จ: {{ upload_store_hl.success_count }}
          </h5>
        </div>
        <div class="col-xl-3 col-sm-6">
          <h5 style="color: red">
            พบข้อผิดพลาด: {{ upload_store_hl.failed_count }}
          </h5>
        </div> -->
      </div>

      <div class="card-body btn-showcase">
        <label
          class="btn btn-primary"
          :class="{ disabled: upload_store_hl.isUploading }"
          for="selectBtn"
        >
        <vue-feather type="upload-cloud" size="18"></vue-feather> เลือกไฟล์อัปโหลด
        </label>
        <button
          class="btn btn-success"
          :class="{ disabled: upload_store_hl.isUploading }"
          @click.prevent="
            upload_store_hl.uploadSelectedFiles(
              this.eventDetails.event_key,
              this.eventDetails.event_watermark_hori,
              this.eventDetails.event_watermark_vertical,
              this.user.user_id,
              this.eventDetails.event_id,
              this.eventDetails.server_upload_url_text,
              this.eventDetails.server_upload_url_aws,
              this.eventDetails.is_free_download,
              this.eventDetails.is_add_watermark_for_free_download
            )
          "
          type="button"
          data-bs-toggle="tooltip"
          title="Upload"
        >
          {{ upload_store_hl.failed_count > 0 ? "Retry" : "เริ่มอัปโหลด" }}
        </button>
        <button
          class="btn btn-info"
          :class="{ disabled: upload_store_hl.isUploading }"
          type="button"
          data-bs-toggle="tooltip"
          title="Clear"
          @click.prevent="upload_store_hl.clearFiles"
        >
        <vue-feather type="refresh-cw" size="18"></vue-feather>  รีเซ็ต
        </button>
      </div>
    </div>
    </div>

    <div class="row" v-if="upload_store_hl.selectedFiles.length">
    <div class="col-sm-12">
      <div class="card">
        <div class="my-gallery card-body row gallery-with-description">
          <div
            class="col-xl-4 col-sm-6 upload-item"
            v-for="(file, index) in upload_store_hl.selectedFiles"
            :key="index"
          >
            <div
              :class="{
                'failed-border': file.status == 'failed',
                'success-border': file.status == 'success',
              }"
            >
              <div v-if="file.status !== 'success'">
                <span v-if="file.status == 'pending'">
                  <b>{{ file.file.name }}[Waiting]</b>
                </span>
                <span v-if="file.status == 'failed'">
                  <b class="failed">{{ file.file.name }}[Failed]</b>
                </span>
                <span v-if="file.status === 'uploading'">
                  <!-- uploading...{{ file.percentage }}% -->

                  <b class="uploading"
                    >{{ file.file.name }}[Upload{{ file.percentage }}%]</b
                  >
                </span>
              </div>
              <div v-if="file.status === 'success'">
                <b class="success">{{ file.file.name }}[Completed]</b>
              </div>
            </div>
          </div>
        </div>
        &nbsp;
      </div>
    </div>
    </div>

    
  </div>
  <div v-if="this.is_event_permission === 'CHECKING'">
        <div class="d-flex justify-content-center">authorized checking...</div>
  </div>
  <div v-if="this.is_event_permission === 'N'">
         <div class="d-flex justify-content-center">this page is not authorized</div>
         
         <div v-if="this.is_mhao !== 'Y' && this.loadding === 'N'">
            <div class="d-flex justify-content-center">
                event is not currect!
            </div>
          </div>
  </div>

</div>
  <div></div>
  <!-- Hidden file input -->
  <input
    id="selectBtn"
    type="file"
    multiple
    accept=".jpg, .jpeg"
    title="Upload file"
    @change="upload_store_hl.onSelectFiles"
  />

  <!-- Buttons -->
</template>
  <script>
import { useUploadStore_hl } from "./upload_hl";
//import { useUserStore } from "../../store/modules/user";
import axios from "axios";
import language from '@/components/header/language.vue';
export default {
  components: { language },
  setup() {
    const upload_store_hl = useUploadStore_hl();
    //const user_store = useUserStore();
    //user_store.loadFromLocalStorage();

    return { upload_store_hl };
  },
  data() {
    return {
      eventDetails: {},
      selectedFiles: [],
      processing: 0,
      taged: 0,
      total: 0,
      //user_display: {},
      loading: true,
      user: {},
      qr_code: "https://running.in.th/"+this.$route.params.q,
      is_event_permission: "CHECKING",

    };
  },
  async mounted() {

    try {

      this.user = JSON.parse(localStorage.getItem('user'))
      let _is_event_permission = await this.event_permission();

      //console.log("_is_event_permission:"+_is_event_permission)
      if(_is_event_permission === 'Y'){
        let eventDetail = await this.getEventDetail(this.$route.params.q);
        await this.getTaged(eventDetail.event_id, this.user.user_id);
      }
    } catch (error) {
      alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ หรือลองอีกครั้ง", error);
      location.reload();
    }

  },
  methods: {
    async event_permission() {
      
      try{
        let data = {
          event_key: this.$route.params.q,
        };
        const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
        const response = await axios.post(
          "v1/partner/permission",
          data,
          {
            headers
          }
        );
        //console.log(JSON.stringify(response))
        if (response.data.status === "success") {
          this.is_event_permission = response.data.body.permission
          return response.data.body.permission;
        } else {
          alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด! ");
          return "N"
        }
          
      } catch (error) {
        alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด กรุณาแจ้งผู้ดูแลระบบ")
        return "N"
      }
      
    },


    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;
        return res.data;
      } else {
        alert("[EventDetail]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },
    async getTaged(event_id, photographer_id) {
      const data = {
        event_id: event_id,
        photographer_id: photographer_id,
      };
      const res = await axios.post("/v1/events/taged", data);
      if (res.data.status === "success") {
        this.processing = res.data.processing;
        this.taged = res.data.taged;
        this.total = res.data.total;
      } else {
        alert("[Taged]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },
    cancel() {
      this.$router.push({ path: "/" });
    },
  },
  };
</script>
  <style scoped lang="scss">
#selectBtn {
  display: none;
}
.card-btn-upload {
  text-align: center;
}
.card-file-list {
  text-align: center;
}

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  //padding: 0.5rem;
  //font-size: smaller;
  float: left;
  padding: 5px;
  text-align: center;
}
.drop-container:hover {
  background: #979696ad;
}
.upload-item:hover {
  background: #e0e0e0ad;
  cursor: pointer;
  border: 1px dashed rgba(124, 2, 2, 0.616);
}

.buttons {
  display: flex;
  gap: 1rem;

  &.centered {
    justify-content: center;
  }
}

.button {
  cursor: pointer;

  color: white;
  background: #809af0;
  text-decoration: none;

  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 2px;

  &:hover {
    background: #3f6cff;
  }

  &.disabled {
    cursor: default;
    background: lightgray;
    pointer-events: none;
  }
}

.button-danger {
  background: #f08080;

  &:hover {
    background: #f65959;
  }
}

.button-upload {
  background: #00d591;

  &:hover {
    background: #02b97f;
  }
}

.failed {
  color: red;
}

.success {
  color: green;
}

.uploading {
  color: rgb(255, 102, 0);
}

.failed-border {
  border-color: red;
}

textarea
{
  width:100%;
  background-color: #ffffffea;
  border-radius: .7rem;
}
.textwrapper
{
  border:1px solid #999999;
  margin:5px 0;
  padding:3px;
}
</style>