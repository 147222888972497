export var menuItems={
  "data": [
    {
      "headTitle1": "Running.in.th",
      "headTitle2": "บริการค้นหารอยยิ้ม",
      "type": "headtitle"
    },
    {
      "title": "หน้าหลัก",
      "path": "/",
      "type": "link",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "badgeType": "light-primary",
      "active": false,
      

    },
    {
      "title": "กิจกรรมที่เข้าร่วม",
      "path": "/participate",
      "type": "link",
      "icon": "stroke-calendar",
      "iconf":"fill-calendar",
      "badgeType": "light-primary",
      "active": false,
      

    },
    {
      "title": "กระเป๋าเงิน",
      "icon": "stroke-email",
      "iconf":"fill-email",
      "type": "sub",
      "badgeType": "light-secondary",
      "active": false,
      "children": [
        {
          "path": "/wallet",
          "title": "ถอนเงิน",
          "type": "link",
          "active": false
        },
        {
          "path": "/withdrawal-history",
          "title": "ประวัติการถอนเงิน",
          "type": "link",
          "active": false
        },
        {
          "path": "/credit-create",
          "title": "เครดิต",
          "type": "link",
          "active": false
        }
      ]
    }
  ]
}