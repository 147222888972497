<template>
  <div class="row">&nbsp;</div>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-xxl-12">
              <div class="row widget-grid">
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0">
                            <vue-feather
                              type="server"
                              size="25"
                            ></vue-feather>
                            กระเป๋าเครดิต
                          </h4>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

                <div class="col-sm-12">
                  <h1 style="text-align:center;">เครดิตคงเหลือ {{ this.credit_balance }}</h1>
                  <p>&nbsp;</p>
                  <div class="row">       
                    <hr>
                    <div class="input-group mb-3" >
                      <span class="input-group-text" id="basic-addon1">เติมเครดิต</span>
                      <select class="form-select" v-model="credit_add"  id="floatingSelect">
                        <option value="1000">1,000 เครดิต ราคา 50 บาท</option>
                        <option value="2000">2,000 เครดิต ราคา 100 บาท</option>
                        <option value="3000">3,000 เครดิต ราคา 150 บาท</option>
                        <option value="4000">4,000 เครดิต ราคา 200 บาท</option>
                        <option value="5000">5,000 เครดิต ราคา 250 บาท</option>
                        <option value="10000">10,000 เครดิต ราคา 500 บาท</option>
                       
                      </select>
                    </div>
                    <div class="row">
                    <div class="d-flex justify-content-center">
                      <button type="button" @click.prevent="create_order_credit" style="cursor: pointer" class="btn btn-primary btn-lg">สร้าง Qr code เพื่อสแกนจ่าย</button>
                    </div>
                  </div>
                  </div>
                </div>
            </div>
            <hr v-if="this.qr_url!==''">
            
             <div v-if="this.qr_url!==''">
              <div class="d-flex justify-content-center" >
                รหัสคำสั่งซื้อ: {{ this.credit_create_obj.uuid }}
              </div>
              <div class="d-flex justify-content-center" >
                ยอดชำระ: {{ this.credit_create_obj.amount }}
              </div>
              <div class="d-flex justify-content-center" >
                จำนวนเครดิตที่ได้รับ: {{ this.credit_create_obj.add_credit_unit }}
              </div>
             <div class="d-flex justify-content-center" >
              <img style="width: 200px;a" :src="this.qr_url" >
             </div>
             <div class="d-flex justify-content-center" >
              <a class="btn btn-primary btn" href="" role="button"><vue-feather type="refresh-cw" size="18"> </vue-feather> กดปุ่ม Refresh เมื่อชำระเงินเรียบร้อย</a>
             </div>
            </div>
            
            
          </div>
          </div>
        </div>
        <!-- <AllCampaignsCard /> -->

        <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              
              <div class="col-sm-12">
                  <b style="text-align:center;">เครดิตล่าสุด</b>
                  <hr>
                  <div class="table-responsive currency-table">
                    <table class="table table-striped">
                      <thead>
                        <tr style="font-weight: bold">
                          <th scope="col" style="font-weight: bold">คำสั่งซื้อ</th>
                          <th scope="col" style="text-align: center;">วันที่ชำระเงิน</th>
                          <th scope="col" style="text-align: center;">เครดิต</th>
                        </tr>
                      </thead>
                      <tbody v-if="this.credit_history.length === 0">
                        <tr>
                          <td colspan="9">
                            <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="this.credit_history.length !== 0">
                        <tr v-for="item in this.credit_history" :key="item">
                          <td><b>{{ item.confirm_key }}</b></td>
                          <td style="text-align: center;">{{ item.payment_date_txt }}</td>
                          <td style="text-align: center;color: green;" v-if="item.source === 'order_paid_summary'">+{{ item.refund_credit }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import axios from "axios";
const FormData = require('form-data');
export default {
  data() {
    return {
      user: {},
      token: localStorage.getItem("jwt"),
      credit_add: '1000',
      credit_balance: 0,
      credit_create_obj: {},
      qr_url: "",
      credit_history: {}

    };
  },
  async created(){
    this.token = localStorage.getItem("jwt")
  },
  async mounted() {
    try {
      this.user = JSON.parse(localStorage.getItem("user"));
      await this.getCredit();
      await this.getCreditHistory();
    } catch (error) {
      alert("พบข้อผิดพลาด กรุณาโหลดหน้าใหม่");
    }
  },
  methods: {
    async getCreditHistory() {
      const data = {
        tk: null
      };
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      const res = await axios.post("/v1/utils/credit-history", data,{headers});
      if (res.data.status === "success") {
        this.credit_history = res.data.body;
      } else {
        alert("[getCreditHistory]พบข้อผิดพลาด!");
      }
    },
    async getCredit(event_id, photographer_id) {
      const data = {
        event_id: event_id,
        photographer_id: photographer_id,
      };
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      const res = await axios.post("/v1/utils/credit-balance", data,{headers});
      if (res.data.status === "success") {
        this.credit_balance = res.data.body.credit_balance;
      } else {
        alert("[getCredit]พบข้อผิดพลาด!");
        //this.$router.push({ path: "/" });
      }
    },
    async create_order_credit() {
      const data = {
        add_credit_unit: this.credit_add,
        user_id: this.user.user_id,
      };
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      const res = await axios.post(process.env.VUE_APP_FRONTEND_API +"/v3/order/create-credit", data,{headers});
      if (res.data.status === "success") {
          this.credit_create_obj = res.data.body
          this.qr_url = res.data.body.qr_url
      } else {
        alert("[create_order_credit]พบข้อผิดพลาด!");
        //this.$router.push({ path: "/" });
      }
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    }
    
  },
};
</script>
